/**
 * Icon enums used for determining which icon should be displayed
 */
export enum IconName {
  Archive,
  Audio,
  BackwardOne,
  BackwardTen,
  Block,
  Bulk,
  Calendar,
  Checkmark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ClosedCaption,
  Copy,
  Delete,
  Details,
  Drag,
  Ellipsis,
  Error,
  External,
  File,
  Filters,
  ForwardOne,
  ForwardTen,
  InProgress,
  Info,
  MidlineEllipsis,
  Minus,
  Mute,
  NavigateRight,
  Pause,
  Play,
  Plus,
  Publish,
  QuickEdit,
  QuickEditStation,
  RemoveFilter,
  Replace,
  Retry,
  Save,
  Snapshot,
  Stop,
  Subtitles,
  Success,
  Unarchive,
  Undo,
  Unmute,
  Unpublish,
  Upload,
  Video,
  Warning,
  X,
}
