import clsx from 'clsx';
import React, { FormEvent } from 'react';
import { BaseFormControl } from '../Form.models';
import { FormElementContainer } from '../FormElementContainer';
import classes from './TextArea.scss';

export interface TextAreaProps extends BaseFormControl {
  /** Current value the form control has */
  value?: string | undefined;
  /** Input placeholder */
  placeholder?: string;
  /** Whether or not the control should start focused (default: false) */
  autoFocus?: boolean;
  /** Number of visible text lines (default: 5) */
  rows?: number;

  /** Raised when the value has changed */
  onChange?: (event: FormEvent<HTMLTextAreaElement>) => void;
  /** Raised when the element has been selected */
  onBlur?: (event: FormEvent<HTMLTextAreaElement>) => void;
  /** Raised when the element has been de-selected */
  onFocus?: (event: FormEvent<HTMLTextAreaElement>) => void;
}

/**
 * This component can be used to display a textarea input inside a form.
 * If you run your form in a Formik context, consider using the `TextAreaField` component instead.
 */
export const TextArea: React.FC<TextAreaProps> = ({
  name,
  id,
  value,
  disabled = false,
  placeholder,
  error,
  autoFocus = false,
  rows = 5,
  onChange,
  onBlur,
  onFocus,
  className = '',
  ...rest
}) => {
  const errorMsg: string | undefined = error;

  return (
    <FormElementContainer
      {...rest}
      className={clsx(classes.container, 'text-area-container', className)}
      error={errorMsg}
      dataTestFieldType="TextArea"
      htmlFor={id ?? name}
    >
      <textarea
        className={clsx({ [classes.hasError]: errorMsg !== undefined })}
        id={id ?? name}
        name={name}
        value={value ?? ''}
        disabled={disabled}
        placeholder={disabled ? undefined : placeholder}
        rows={rows}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </FormElementContainer>
  );
};
