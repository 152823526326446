import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonContext } from '../../Buttons';
import { IconName } from '../../Icons';
import classes from './AccordionItem.scss';

export interface AccordionItemProps {
  header: JSX.Element;

  /** Callback to emit when the expand button is clicked. When used within the Accordion component, this callback will be overridden. */
  toggleExpanded?: () => void;

  /** The current expanded state of the item. When used within the Accordion component, this prop will be overridden. */
  isExpanded?: boolean;

  /** Determines if spacing will be added to align with the Accordion header (default: true) */
  allowLeftSpacing?: boolean;

  /** Sticky state of the current row. When used within the Accordion component, this prop will be overridden with the value from stickyRows. */
  sticky?: boolean;

  /** CSS Class name for additional styles */
  className?: string;
}

/**
 * Renders a single Accordion Item
 * @example
 *  <AccordionItem header={<b>Item 2</b>}>
 *    <p>Content 2</p>
 *  </AccordionItem>
 */
export const AccordionItem: React.FC<AccordionItemProps> = ({
  header,
  children,
  toggleExpanded,
  isExpanded = false,
  allowLeftSpacing = true,
  sticky = false,
  className,
}) => {
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      setScrollHeight(elementRef.current.scrollHeight);
    }
  }, [scrollHeight, children]);

  return (
    <div data-test-id="accordion-item" className={className}>
      <div
        data-test-id="accordion-item-row"
        className={clsx(
          classes.row,
          { [classes.sticky]: sticky },
          {
            [classes.rowExpanded]: isExpanded,
          },
        )}
        onClick={toggleExpanded}
      >
        <Button
          icon={IconName.ChevronRight}
          className={clsx(classes.button, {
            [classes.rotated]: isExpanded,
          })}
          buttonContext={ButtonContext.None}
        />
        {header}
      </div>
      <div
        data-test-id="accordion-item-content"
        ref={elementRef}
        className={
          isExpanded
            ? clsx(classes.container, classes.expanded)
            : classes.container
        }
        style={{ maxHeight: isExpanded ? scrollHeight : 0 }}
      >
        <div
          className={classes.content}
          style={{ gridTemplateColumns: !allowLeftSpacing ? '1fr' : undefined }}
        >
          {allowLeftSpacing && <div></div>}
          {children}
        </div>
      </div>
    </div>
  );
};
