import clsx from 'clsx';
import React from 'react';
import { ReactTabsFunctionComponent, TabList, TabListProps } from 'react-tabs';
import classes from './CustomTabList.scss';
import { ScrollContainer } from './ScrollContainer';

export const CustomTabList: ReactTabsFunctionComponent<TabListProps> = ({
  children,
  className,
  ...otherProps
}) => (
  <TabList
    {...otherProps}
    className={clsx(classes.tablist, className)}
    data-test-id="tab-list"
  >
    <ScrollContainer>{children}</ScrollContainer>
  </TabList>
);

CustomTabList.tabsRole = 'TabList';
