import clsx from 'clsx';
import React from 'react';
import { BaseFormElement } from '../Form.models';
import { FormElementContainer } from '../FormElementContainer';
import classes from './BooleanViewField.scss';

export interface BooleanViewFieldProps extends BaseFormElement {
  /** Value to be displayed */
  value?: boolean;
  /** text to be displayed when value is true */
  trueLabel?: string;
  /** text to be displayed when value is true */
  falseLabel?: string;
}

export const BooleanViewField: React.FC<BooleanViewFieldProps> = ({
  value = false,
  trueLabel = 'True',
  falseLabel = 'False',
  className = '',
  ...rest
}) => {
  return (
    <FormElementContainer
      {...rest}
      className={clsx(
        classes.container,
        'boolean-view-field-container',
        className,
      )}
      dataTestFieldType="BooleanView"
    >
      <div className={clsx(classes.value)}>
        <div className={clsx(value ? classes.true : classes.false)}></div>
        <div data-test-id="form-field-value" data-test-value={value}>
          {value ? trueLabel : falseLabel}
        </div>
      </div>
    </FormElementContainer>
  );
};
